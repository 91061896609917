<!-- Withdraw  提现 -->
<template>
    <div class="box">
        <div class="header">
            <div class="nav">
                <div style="width: 68px;">
                    <img class="back" src="~@/assets/images/distributionIndex/back.png" alt="">
                </div>
                <div class="title">提现</div>
                <div class="info">
                    <span>提现明细</span>
                    <img class="rightIconW" src="~@/assets/images/distributionIndex/rightIconW.png" alt="">
                </div>
            </div>

            <p style="font-size: 12px;font-weight: normal;color: #FFFFFF;padding: 26px 24px 0;">可提现金额</p>
            <div class="moneyinfo">
                <div>
                    <span>2323</span>
                    <span>元</span>
                </div>
                <div>已累计赚取34343元</div>
            </div>
        </div>
        <div class="content">
            <div class="inpBox">
                <p class="p1">提现金额<span>提现需收取3.5%的手续费</span></p>
                <div class="inp">
                    <van-field
                    v-model="money"
                    center
                    placeholder="输入提现金额"
                    >
                    <template #button>
                        <span style="color: #FF7C05;font-weight: 500;">全部</span>
                    </template>
                    </van-field>
                </div>
                <p class="p1">提现至</p>

                <div class="cardlist">
                    <div class="card-item">
                        <div class="left">
                            <img src="~@/assets/images/distributionIndex/alipay.png" alt="">
                            <!-- <img src="~@/assets/images/distributionIndex/bankCard.png" alt=""> -->
                            <span style="width:140px;">支付宝账号</span>

                              <img src="~@/assets/images/distributionIndex/changeCard.png" alt="">
                        </div>
                        <div class="right">
                            <img src="~@/assets/images/distributionIndex/unselect.png" alt="">
                            <!-- <img src="~@/assets/images/distributionIndex/selected.png" alt=""> -->
                        </div>
                    </div>


                </div>

               
            </div>
             <div class="btn">提现</div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Withdraw',
    data() {
        return {
            money:''
        }

    },
    mounted() { }
};
</script>
<style scoped lang="less">
@W: 100%;
@H: 24px;

.base(@W: 100%; @H: 30px; @bgc: none; ) {
    width: @W;
    min-height: @H;
    background-color: @bgc;
    box-sizing: border-box;
}

.box {
    width: 375px;
    min-height: 100vh;

    .header {
        width: 100%;
        height: 310px;
        background: url('~@/assets/images/distributionIndex/Withdraw.png') no-repeat;
        background-size: contain;
        box-sizing: border-box;
        padding-top: 30px;

        // margin-top: 30px;
        // background-attachment: fixed;
        .nav {
            box-sizing: border-box;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .base();

            .back {
                width: 24px;
                height: 24px;
            }

            .title {

                font-size: 16px;
                color: white;
            }

            .info {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: white;
            }

            .rightIconW {
                width: 10px;
                height: 13px;
                margin-left: 10px;
            }
        }


        .moneyinfo {
            padding: 0 24px;
            display: flex;
            .base(@H: 72px; );
            // box-sizing: border-box;
            justify-content: space-between;
            align-items: flex-end;
            font-size: 14px;
            font-weight: normal;
            color: #FFFFFF;

            div:nth-child(1) {

                span:nth-child(1) {
                    font-size: 50px;
                    color: #FFFFFF;
                    font-weight: 500;
                }
            }

        }
    }

    .content {
        height: calc(100vh - 310px);
        width: 100%;
        background-color: #f8f9fa;
        padding: 0 15px;
        box-sizing: border-box;
        position: relative;

        .inpBox {

            .base(@bgc: #FFFFFF; );
            width: 345px;
            border-radius: 10px;
            box-shadow: 0px 2px 10px 0px rgba(0, 18, 138, 0.06);
            position: absolute;
            top: -108px;
            padding: 23px 20px;


            .p1 {
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0em;
                color: #333333;

                span {
                    font-size: 12px;
                    font-weight: normal;
                    letter-spacing: 0em;
                    color: #999999;
                    margin-left: 20px;
                }
            }
            .inp {
                margin: 10px 0 25px 0;
                /deep/ .van-cell{
                    background-color: #eff3f6;
                    border-radius: 6px;
                }
            }
        }

        .card-item {
            margin-top: 21px;
            width: 100%;
            height: 24px;
            display: flex;
            justify-content: space-between;
            .left {
                width: 211px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img{
                    width: 24px;
                    height: 18px;
                    margin-right: 10px;
                }
            }
            .right {
                display: flex;
                justify-content: space-between;
                img {
                    width: 21px;
                    height: 21px;
                }
            }
        }

        .btn {
            position: fixed;
            left: 50%;
            // top: 200px;
            bottom: 40px;
            transform: translateX(-50%);
            text-align: center;
            line-height: 43px;
            width: 307px;
            height: 43px;
            border-radius: 21px;
            color: #fff;
            background: linear-gradient(294deg, #FF7404 47%, #FF7404 48%, #FF8F06 93%, #FF8F06 93%);
            box-shadow: 0px 2px 10px 0px rgba(0, 18, 138, 0.06);
        }
          .btn:active {
            background: linear-gradient(294deg, #EE6F08 47%, #EE6F08 48%, #F18603 93%, #F18603 93%);
          } 
    }
}
</style>